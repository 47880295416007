import util from "@/common/util";
import { accountType, levelType } from "@/consts";
import ops from "@/consts/ops";
import { computed, defineAsyncComponent } from "vue";
import { RouterView } from "vue-router";
import { cache, cacheKeys } from "../store";
import { fuTianSchoolIdIds } from "@/consts/index";

const user = cache.get(cacheKeys.user);
const isWulg = user.schoolId == "5bfc83356629438e801a5af615f4a6c2";
const isHg = computed(() => {
  let installCompanyId = user.installCompanyId;
  if (installCompanyId === "1962e5c8659c4af6a89bf27909f2afe0") {
    return true;
  }
  return false;
});
const isFuTian = computed(() => {
  let schoolId = user.schoolId;
  if (fuTianSchoolIdIds.includes(schoolId)) {
    return true;
  }
  return false;
});
const getName = (type) => {
  let str = "";
  if (type == 1) {
    str = isHg.value ? (isWulg ? "食堂自查" : "学校自查") : "企业自查";
  } else if (type == 2) {
    str = isHg.value ? (isWulg ? "食堂自查统计" : "学校自查统计") : "企业自查统计";
  
  } else if (type == 3) {
    str = isHg.value ? (isWulg ? "食堂信用报告查询" : "学校信用报告查询") : "企业信用报告查询";
  
  } else if (type == 4) {
    str = isHg.value ? (isWulg ? "食堂信用分查询" : "学校信用分查询") : "企业信用分查询";
  
  } else if (type == 5) {
    str = isHg.value ? (isWulg ? "食堂注册管理" : "学校注册管理") : "企业注册管理";
  }
  return str;
};

const IconFileEarmarkBarGraph = defineAsyncComponent(() =>
  import("~icons/bi/file-earmark-bar-graph")
);
const IconCamera = defineAsyncComponent(() =>
  import("~icons/icon-park-outline/surveillance-cameras")
);
const IconMouse = defineAsyncComponent(() =>
  import("~icons/emojione-monotone/mouse-face")
);
const IconPostageFill = defineAsyncComponent(() =>
  import("~icons/bi/postage-fill")
);
const IconIOT = defineAsyncComponent(() => import("~icons/eos-icons/iot"));
const IconGAS = defineAsyncComponent(() => import("~icons/mdi/gas-burner"));
const IconSmoke = defineAsyncComponent(() => import("~icons/carbon/smoke"));
const IconLocation = defineAsyncComponent(() =>
  import("~icons/carbon/location-filled")
);
const aiMessageType = cache.raw(cacheKeys.aiMessageType);
const iotDeviceType = cache.raw(cacheKeys.iotDeviceType);
const ledgerType = cache.get(cacheKeys.ledgerType);
const ledgerTypeValues = Object.values(ledgerType);
const nutritionAnalysisEnable = cache.get(
  cacheKeys.companyInfo
).nutritionAnalysisEnable;


const isWh =
  user.installCompanyId && user.installCompanyId.indexOf("44444") > 0
    ? true
    : false;

const _routes = [
  {
    path: "/app",
    meta: {
      title: "智慧食安",
      isShow: () => user.id,
      header: {
        isShow: () => !util.isIframe(),
      },
      navbar: {
        isShow: () => !util.isIframe(),
      },
      sidebar: {
        org: {
          isShow: () => !util.isIframe(),
        },
      },
    },
    component: () => import("@/views/base/AppView"),
    children: [
      {
        path: "/app/home",
        meta: {
          title: "首页",
          isShow: () =>
            accountType.setup.eq(user.type) ||
            accountType.supervise.eq(user.type),
          navbar: {
            isShow: () => false,
          },
          sidebar: {
            isShow: () => false,
          },
        },
        component: () => import("@/views/app/home/IndexView"),
      },
      {
        path: "/app/mclz",
        meta: {
          title: "明厨亮灶",
          sidebar: {
            module: {
              isShow: () => false,
            },
          },
          body: {
            description: "请在左侧选择单位后查看",
            isShow: ({ org }) => org.isCompany || org.isDangkou,
          },
        },
        component: () => import("@/views/app/mclz/IndexView"),
      },
      {
        path: "/app/spzs",
        meta: {
          title: "视频展示",
          isShow: () => false,
          sidebar: {
            isShow: () => false,
          },
        },
        component: () => import("@/views/app/spzs/IndexView"),
      },
      {
        path: "/app/satz",
        meta: {
          title: "食安台账",
          isShow: () => {
            return ledgerTypeValues.filter((d) => d.type === "SA").length > 0;
          },
        },
        component: RouterView,
        children: [
          {
            path: "/app/satz/spcg",
            name: "satz_AA",
            meta: {
              title: "食品采购台账",
              icon: defineAsyncComponent(() => import("~icons/bi/upc-scan")),
              body: {
                ops: [ops.export],
              },
              isShow: () => {
                return ledgerType["AA"] !== undefined;
              },
            },
            component: () => import("@/views/app/satz/spcg/IndexView"),
          },
          {
            path: "/app/satz/scjc",
            name: "satz_CC",
            meta: {
              title: "食材检测台账",
              icon: defineAsyncComponent(() =>
                import("~icons/bi/droplet-half")
              ),
              body: {
                ops: [ops.export],
              },
              isShow: () => {
                return ledgerType["CC"] !== undefined;
              },
            },
            component: () => import("@/views/app/satz/scjc/IndexView"),
          },
          {
            path: "/app/satz/cjxx",
            name: "satz_NN",
            meta: {
              title: "晨检信息台账",
              icon: defineAsyncComponent(() =>
                import("~icons/bi/person-bounding-box")
              ),
              body: {
                ops: [
                  {
                    ...ops.delete,
                    isShow: (row) => {
                      return (
                        accountType.setup.eq(user.type) &&
                        row.row.isAbnormal == "1"
                      );
                    },
                  },
                  ops.export,
                ],
              },
              isShow: () => {
                return ledgerType["NN"] !== undefined;
              },
            },
            component: () => import("@/views/app/satz/cjxx/IndexView"),
          },
          {
            path: "/app/satz/tjj",
            name: "satz_BB",
            meta: {
              title: "添加剂采购台账",
              icon: defineAsyncComponent(() => import("~icons/bi/eyedropper")),
              body: {
                ops: [ops.export],
              },
              isShow: () => {
                return ledgerType["BB"] !== undefined;
              },
            },
            component: () => import("@/views/app/satz/tjj/IndexView"),
          },
          {
            path: "/app/satz/zxwd",
            name: "satz_PP",
            meta: {
              title: "中心温度台账",
              icon: defineAsyncComponent(() =>
                import("~icons/carbon/temperature-celsius")
              ),
              body: {
                ops: [
                  {
                    ...ops.create,
                    isShow: ({ org }) =>
                      !accountType.supervise.eq(user.type) &&
                      (org.isCompany || org.isDangkou),
                  },
                  ops.export,
                  {
                    ...ops.delete,
                    isShow: () => accountType.supervise.eq(user.type),
                  },
                ],
                editPage: defineAsyncComponent(() =>
                  import("@/views/app/satz/zxwd/EditView")
                ),
              },
              isShow: () => {
                return ledgerType["PP"] !== undefined;
              },
            },
            component: () => import("@/views/app/satz/zxwd/IndexView"),
          },
          {
            path: "/app/satz/sply",
            name: "satz_DD",
            meta: {
              title: "食品留样台账",
              icon: defineAsyncComponent(() => import("~icons/bi/receipt")),
              body: {
                ops: [ops.export],
              },
              isShow: () => {
                return ledgerType["DD"] !== undefined;
              },
            },
            component: () => import("@/views/app/satz/sply/IndexView"),
          },
          {
            name: "satz_EE&HH",
            path: "/app/satz/cjqxxd",
            meta: {
              title: "餐具清洗消毒台账",
              icon: defineAsyncComponent(() =>
                import("~icons/bi/shield-shaded")
              ),
              isShow: () =>
                ledgerType["EE"] !== undefined ||
                ledgerType["HH"] !== undefined,
            },
            component: RouterView,
            children: [
              {
                path: "/app/satz/cjqxxd/cjqx",
                name: "satz_EE",
                meta: {
                  title: "餐具清洗台账",
                  icon: defineAsyncComponent(() => import("~icons/bi/water")),
                  body: {
                    ops: [ops.export],
                  },
                  isShow: () => {
                    return ledgerType["EE"] !== undefined;
                  },
                },
                component: () => import("@/views/app/satz/cjqx/IndexView"),
              },
              {
                path: "/app/satz/cjqxxd/cjxd",
                name: "satz_HH",
                meta: {
                  title: "餐具消毒台账",
                  icon: defineAsyncComponent(() =>
                    import("~icons/bi/shield-check")
                  ),
                  body: {
                    ops: [ops.export],
                  },
                  isShow: () => {
                    return ledgerType["HH"] !== undefined;
                  },
                },
                component: () => import("@/views/app/satz/cjxd/IndexView"),
              },
            ],
          },
          {
            path: "/app/satz/hjws",
            name: "satz_GG",
            meta: {
              title: "环境卫生台账",
              icon: defineAsyncComponent(() => import("~icons/bi/tree")),
              body: {
                ops: [ops.export],
              },
              isShow: () => {
                return ledgerType["GG"] !== undefined;
              },
            },
            component: () => import("@/views/app/satz/hjws/IndexView"),
          },
          {
            path: "/app/satz/fqwcl",
            name: "satz_FF",
            meta: {
              title: "废弃物处理台账",
              icon: defineAsyncComponent(() => import("~icons/bi/trash3")),
              body: {
                ops: [ops.export],
              },
              isShow: () => {
                return ledgerType["FF"] !== undefined;
              },
            },
            component: () => import("@/views/app/satz/fqwcl/IndexView"),
          },
          {
            path: "/app/satz/kltj",
            name: "satz_OO",
            meta: {
              title: "客流统计台账",
              icon: defineAsyncComponent(() => import("~icons/bi/people")),

              isShow: () => {
                return ledgerType["OO"] !== undefined;
              },
            },
            component: () => import("@/views/app/satz/kltj/IndexView"),
          },
          {
            path: "/app/satz/xxlrtj",
            meta: {
              title: "信息录入统计",
              icon: IconFileEarmarkBarGraph,
            },
            component: () => import("@/views/app/satz/xxlrtj/IndexView"),
          },
          {
            path: "/app/satz/statistics",
            meta: {
              title: "食安台账统计",
              icon: defineAsyncComponent(() =>
                import("~icons/bi/bar-chart-line")
              ),
              isShow: () =>
                accountType.setup.eq(user.type) ||
                accountType.supervise.eq(user.type),
              sidebar: {
                org: {
                  level: levelType.street,
                },
              },
              body: {},
            },
            component: () => import("@/views/app/satz/statistics/IndexView"),
          },
        ],
      },
      {
        path: "/app/qyzc",
        meta: {
          // title: isWulg ? "食堂自查" : "企业自查",
          title: getName(1),
          isShow: () => {
            return ledgerTypeValues.filter((d) => d.type === "ZC").length > 0;
          },
        },
        component: RouterView,
        children: [
          {
            path: "/app/qyzc/rc",
            meta: {
              title: "日查",
              iconText: "日",
              body: {
                ops: [ops.download, ops.export],
              },
              isShow: () => {
                return ledgerType["II"] !== undefined;
              },
            },
            component: () => import("@/views/app/qyzc/rc/IndexView"),
          },
          {
            path: "/app/qyzc/zc",
            meta: {
              title: "周查",
              iconText: "周",
              body: {
                ops: [ops.download, ops.export],
              },
              isShow: () => {
                return ledgerType["JJ"] !== undefined;
              },
            },
            component: () => import("@/views/app/qyzc/zc/IndexView"),
          },
          {
            path: "/app/qyzc/yc",
            meta: {
              title: "月查",
              iconText: "月",
              body: {
                ops: [ops.download, ops.export],
              },
              isShow: () => {
                return ledgerType["KK"] !== undefined;
              },
            },
            component: () => import("@/views/app/qyzc/yc/IndexView"),
          },
          {
            path: "/app/qyzc/pcjl",
            meta: {
              title: "陪餐记录",
              icon: defineAsyncComponent(() =>
                import("~icons/bi/file-earmark-person-fill")
              ),
              body: {
                ops: [ops.export],
              },

              isShow: () => {
                return ledgerType["LL"] !== undefined;
              },
            },
            component: () => import("@/views/app/qyzc/pcjl/IndexView"),
          },
          {
            path: "/app/qyzc/mrcp",
            meta: {
              title: "每日菜谱",
              icon: defineAsyncComponent(() =>
                import("~icons/bi/journal-text")
              ),
              body: {
                ops: [ops.export],
              },

              isShow: () => {
                return ledgerType["MM"] !== undefined;
              },
            },
            component: () => import("@/views/app/qyzc/mrcp/IndexView"),
          },
          {
            path: "/app/qyzc/xxlrtj",
            meta: {
              title: "信息录入统计",
              icon: IconFileEarmarkBarGraph,
            },
            component: () => import("@/views/app/qyzc/xxlrtj/IndexView"),
          },
          {
            path: "/app/qyzc/statistics",
            meta: {
              // title: "企业自查统计",
              title: getName(2),
              icon: defineAsyncComponent(() =>
                import("~icons/bi/bar-chart-line")
              ),
              sidebar: {
                org: {
                  level: levelType.street,
                },
              },
              isShow: () =>
                accountType.setup.eq(user.type) ||
                accountType.supervise.eq(user.type),
              body: {},
            },
            component: () => import("@/views/app/qyzc/statistics/IndexView"),
          },
        ],
      },
      {
        path: "/app/znyj",
        meta: {
          title: "智能预警",
        },
        component: RouterView,
        children: [
          {
            path: "/app/znyj/zjxx",
            meta: {
              title: "证件信息",
              icon: defineAsyncComponent(() => import("~icons/bi/stack")),
            },
            component: RouterView,
            children: [
              {
                path: "/app/znyj/zjxx/yyzz",
                meta: {
                  title: "营业执照",
                  icon: defineAsyncComponent(() =>
                    import("~icons/bi/aspect-ratio")
                  ),
                  body: {
                    ops: [ops.export],
                  },
                },
                component: () => import("@/views/app/znyj/zjxx/yyzz/IndexView"),
              },
              {
                path: "/app/znyj/zjxx/spjyxkz",
                meta: {
                  title: "食品经营许可证",
                  icon: defineAsyncComponent(() =>
                    import("~icons/bi/aspect-ratio-fill")
                  ),
                  body: {
                    ops: [ops.export],
                  },
                },
                component: () =>
                  import("@/views/app/znyj/zjxx/spjyxkz/IndexView"),
              },
              {
                path: "/app/znyj/zjxx/saypxzs",
                meta: {
                  title: "食安员培训证书",
                  icon: defineAsyncComponent(() =>
                    import("~icons/bi/postcard")
                  ),
                  body: {
                    ops: [ops.export],
                  },
                },
                component: () =>
                  import("@/views/app/znyj/zjxx/saypxzs/IndexView"),
              },
              {
                path: "/app/znyj/zjxx/jkz",
                meta: {
                  title: "健康证",
                  icon: defineAsyncComponent(() =>
                    import("~icons/bi/postcard-heart")
                  ),
                  body: {
                    ops: [ops.export],
                  },
                },
                component: () => import("@/views/app/znyj/zjxx/jkz/IndexView"),
              },
            ],
          },
          {
            path: "/app/znyj/satzyj",
            meta: {
              title: "食安台账预警",
              icon: defineAsyncComponent(() =>
                import("~icons/bi/clipboard2-heart")
              ),
              body: {
                ops: [ops.export],
              },
              isShow: () => {
                return Object.keys(ledgerType).length > 0;
              },
            },
            component: () => import("@/views/app/znyj/satzyj/IndexView"),
          },
          {
            path: "/app/znyj/aiyj",
            meta: {
              title: "AI行为分析",
              icon: defineAsyncComponent(() =>
                import("~icons/bi/exclamation-triangle")
              ),
              isShow: () => {
                return Object.keys(aiMessageType).length > 0;
              },
            },
            component: RouterView,
            children: [
              {
                path: "/app/znyj/aiyj/laoshu",
                name: "aiyj_0",
                meta: {
                  title: "生物识别",
                  icon: IconMouse,
                  body: {
                    hasVideo: () => aiMessageType["0"].hasVideo,
                    ops: [
                      {
                        ...ops.deleteBatch,
                        isShow: ({ rows }) =>
                          accountType.setup.eq(user.type) && rows.length > 0,
                      },
                      {
                        ...ops.exportList,
                        isShow: ({ search }) =>
                          accountType.supervise.eq(user.type) &&
                          isFuTian.value &&
                          search.startTime &&
                          search.endTime,
                      },
                    ],
                  },
                  isShow: () => {
                    return aiMessageType["0"] !== undefined;
                  },
                },
                component: () => import("@/views/app/znyj/aiyj/DataView"),
              },
              {
                path: "/app/znyj/aiyj/maozi",
                name: "aiyj_9",
                meta: {
                  title: "未戴帽子",
                  icon: defineAsyncComponent(() =>
                    import("~icons/icon-park-outline/chef-hat-one")
                  ),
                  body: {
                    hasVideo: () => aiMessageType["9"].hasVideo,
                    ops: [
                      {
                        ...ops.deleteBatch,
                        isShow: ({ rows }) =>
                          accountType.setup.eq(user.type) && rows.length > 0,
                      },
                      {
                        ...ops.exportList,
                        isShow: ({ search }) =>
                          accountType.supervise.eq(user.type) &&
                          isFuTian.value &&
                          search.startTime &&
                          search.endTime,
                      },
                    ],
                  },
                  isShow: () => {
                    return aiMessageType["9"] !== undefined;
                  },
                },
                component: () => import("@/views/app/znyj/aiyj/DataView"),
              },
              {
                path: "/app/znyj/aiyj/kouzhao",
                name: "aiyj_4",
                meta: {
                  title: "未戴口罩",
                  icon: defineAsyncComponent(() =>
                    import("~icons/uim/head-side-mask")
                  ),
                  body: {
                    hasVideo: () => aiMessageType["4"].hasVideo,
                    ops: [
                      {
                        ...ops.deleteBatch,
                        isShow: ({ rows }) =>
                          accountType.setup.eq(user.type) && rows.length > 0,
                      },
                      {
                        ...ops.exportList,
                        isShow: ({ search }) =>
                          accountType.supervise.eq(user.type) &&
                          isFuTian.value &&
                          search.startTime &&
                          search.endTime,
                      },
                    ],
                  },
                  isShow: () => {
                    return aiMessageType["4"] !== undefined;
                  },
                },
                component: () => import("@/views/app/znyj/aiyj/DataView"),
              },
              {
                path: "/app/znyj/aiyj/yanhuo",
                name: "aiyj_1",
                meta: {
                  title: "烟火识别",
                  icon: defineAsyncComponent(() =>
                    import("~icons/ph/fire-fill")
                  ),
                  body: {
                    hasVideo: () => aiMessageType["1"].hasVideo,
                    ops: [
                      {
                        ...ops.deleteBatch,
                        isShow: ({ rows }) =>
                          accountType.setup.eq(user.type) && rows.length > 0,
                      },
                      {
                        ...ops.exportList,
                        isShow: ({ search }) =>
                          accountType.supervise.eq(user.type) &&
                          isFuTian.value &&
                          search.startTime &&
                          search.endTime,
                      },
                    ],
                  },
                  isShow: () => {
                    return aiMessageType["1"] !== undefined;
                  },
                },
                component: () => import("@/views/app/znyj/aiyj/DataView"),
              },
              {
                path: "/app/znyj/aiyj/mhlg",
                name: "aiyj_8",
                meta: {
                  title: "明火离岗",
                  icon: defineAsyncComponent(() =>
                    import("~icons/maki/fire-station-11")
                  ),
                  body: {
                    hasVideo: () => aiMessageType["8"].hasVideo,
                    ops: [
                      {
                        ...ops.deleteBatch,
                        isShow: ({ rows }) =>
                          accountType.setup.eq(user.type) && rows.length > 0,
                      },
                      {
                        ...ops.exportList,
                        isShow: ({ search }) =>
                          accountType.supervise.eq(user.type) &&
                          isFuTian.value &&
                          search.startTime &&
                          search.endTime,
                      },
                    ],
                  },
                  isShow: () => {
                    return aiMessageType["8"] !== undefined;
                  },
                },
                component: () => import("@/views/app/znyj/aiyj/DataView"),
              },
              {
                path: "/app/znyj/aiyj/rylg",
                name: "aiyj_19",
                meta: {
                  title: "人员离岗",
                  icon: defineAsyncComponent(() =>
                    import("~icons/mdi/account-off-outline")
                  ),
                  body: {
                    hasVideo: () => aiMessageType["19"].hasVideo,
                    ops: [
                      {
                        ...ops.deleteBatch,
                        isShow: ({ rows }) =>
                          accountType.setup.eq(user.type) && rows.length > 0,
                      },
                      {
                        ...ops.exportList,
                        isShow: ({ search }) =>
                          accountType.supervise.eq(user.type) &&
                          isFuTian.value &&
                          search.startTime &&
                          search.endTime,
                      },
                    ],
                  },
                  isShow: () => {
                    return aiMessageType["19"] !== undefined;
                  },
                },
                component: () => import("@/views/app/znyj/aiyj/DataView"),
              },
              {
                path: "/app/znyj/aiyj/ffcr",
                name: "aiyj_7",
                meta: {
                  title: "非法闯入",
                  icon: defineAsyncComponent(() =>
                    import("~icons/mdi/target-account")
                  ),
                  body: {
                    hasVideo: () => aiMessageType["7"].hasVideo,
                    ops: [
                      {
                        ...ops.deleteBatch,
                        isShow: ({ rows }) =>
                          accountType.setup.eq(user.type) && rows.length > 0,
                      },
                      {
                        ...ops.exportList,
                        isShow: ({ search }) =>
                          accountType.supervise.eq(user.type) &&
                          isFuTian.value &&
                          search.startTime &&
                          search.endTime,
                      },
                    ],
                  },
                  isShow: () => {
                    return aiMessageType["7"] !== undefined;
                  },
                },
                component: () => import("@/views/app/znyj/aiyj/DataView"),
              },
              {
                path: "/app/znyj/aiyj/yjrycr",
                name: "aiyj_13",
                meta: {
                  title: "夜间人员闯入",
                  icon: defineAsyncComponent(() =>
                    import("~icons/ic/round-night-shelter")
                  ),
                  body: {
                    hasVideo: () => aiMessageType["13"].hasVideo,
                    ops: [
                      {
                        ...ops.deleteBatch,
                        isShow: ({ rows }) =>
                          accountType.setup.eq(user.type) && rows.length > 0,
                      },
                      {
                        ...ops.exportList,
                        isShow: ({ search }) =>
                          accountType.supervise.eq(user.type) &&
                          isFuTian.value &&
                          search.startTime &&
                          search.endTime,
                      },
                    ],
                  },
                  isShow: () => {
                    return aiMessageType["13"] !== undefined;
                  },
                },
                component: () => import("@/views/app/znyj/aiyj/DataView"),
              },
              {
                path: "/app/znyj/aiyj/cysb",
                name: "aiyj_5",
                meta: {
                  title: "抽烟识别",
                  icon: defineAsyncComponent(() =>
                    import("~icons/ic/twotone-smoke-free")
                  ),
                  body: {
                    hasVideo: () => aiMessageType["5"].hasVideo,
                    ops: [
                      {
                        ...ops.deleteBatch,
                        isShow: ({ rows }) =>
                          accountType.setup.eq(user.type) && rows.length > 0,
                      },
                      {
                        ...ops.exportList,
                        isShow: ({ search }) =>
                          accountType.supervise.eq(user.type) &&
                          isFuTian.value &&
                          search.startTime &&
                          search.endTime,
                      },
                    ],
                  },
                  isShow: () => {
                    return aiMessageType["5"] !== undefined;
                  },
                },
                component: () => import("@/views/app/znyj/aiyj/DataView"),
              },
              {
                path: "/app/znyj/aiyj/wsj",
                name: "aiyj_2",
                meta: {
                  title: "玩手机",
                  icon: defineAsyncComponent(() =>
                    import("~icons/bi/phone-flip")
                  ),
                  body: {
                    hasVideo: () => aiMessageType["2"].hasVideo,
                    ops: [
                      {
                        ...ops.deleteBatch,
                        isShow: ({ rows }) =>
                          accountType.setup.eq(user.type) && rows.length > 0,
                      },
                      {
                        ...ops.exportList,
                        isShow: ({ search }) =>
                          accountType.supervise.eq(user.type) &&
                          isFuTian.value &&
                          search.startTime &&
                          search.endTime,
                      },
                    ],
                  },
                  isShow: () => {
                    return aiMessageType["2"] !== undefined;
                  },
                },
                component: () => import("@/views/app/znyj/aiyj/DataView"),
              },
              {
                path: "/app/znyj/aiyj/ljtwg",
                name: "aiyj_22",
                meta: {
                  title: "垃圾桶未盖",
                  icon: defineAsyncComponent(() =>
                    import("~icons/fa6-solid/trash-arrow-up")
                  ),
                  body: {
                    hasVideo: () => aiMessageType["22"].hasVideo,
                    ops: [
                      {
                        ...ops.deleteBatch,
                        isShow: ({ rows }) =>
                          accountType.setup.eq(user.type) && rows.length > 0,
                      },
                      {
                        ...ops.exportList,
                        isShow: ({ search }) =>
                          accountType.supervise.eq(user.type) &&
                          isFuTian.value &&
                          search.startTime &&
                          search.endTime,
                      },
                    ],
                  },
                  isShow: () => {
                    return aiMessageType["22"] !== undefined;
                  },
                },
                component: () => import("@/views/app/znyj/aiyj/DataView"),
              },
              {
                path: "/app/znyj/aiyj/sbzj",
                name: "aiyj_15",
                meta: {
                  title: "设备自检",
                  icon: defineAsyncComponent(() =>
                    import("~icons/majesticons/settings-cog-check")
                  ),
                  body: {
                    hasVideo: () => aiMessageType["15"].hasVideo,
                    ops: [
                      {
                        ...ops.deleteBatch,
                        isShow: ({ rows }) =>
                          accountType.setup.eq(user.type) && rows.length > 0,
                      },
                      {
                        ...ops.exportList,
                        isShow: ({ search }) =>
                          accountType.supervise.eq(user.type) &&
                          isFuTian.value &&
                          search.startTime &&
                          search.endTime,
                      },
                    ],
                  },
                  isShow: () => {
                    return aiMessageType["15"] !== undefined;
                  },
                },
                component: () => import("@/views/app/znyj/aiyj/DataView"),
              },
              {
                path: "/app/znyj/aiyj/xjmh",
                name: "aiyj_10",
                meta: {
                  title: "相机模糊",
                  icon: IconCamera,
                  body: {
                    hasVideo: () => aiMessageType["10"].hasVideo,
                    ops: [
                      {
                        ...ops.deleteBatch,
                        isShow: ({ rows }) =>
                          accountType.setup.eq(user.type) && rows.length > 0,
                      },
                      {
                        ...ops.exportList,
                        isShow: ({ search }) =>
                          accountType.supervise.eq(user.type) &&
                          isFuTian.value &&
                          search.startTime &&
                          search.endTime,
                      },
                    ],
                  },
                  isShow: () => {
                    return aiMessageType["10"] !== undefined;
                  },
                },
                component: () => import("@/views/app/znyj/aiyj/DataView"),
              },
              {
                path: "/app/znyj/aiyj/xjzd",
                name: "aiyj_11",
                meta: {
                  title: "相机遮挡",
                  icon: defineAsyncComponent(() =>
                    import("~icons/icon-park-outline/surveillance-cameras-one")
                  ),
                  body: {
                    hasVideo: () => aiMessageType["11"].hasVideo,
                    ops: [
                      {
                        ...ops.deleteBatch,
                        isShow: ({ rows }) =>
                          accountType.setup.eq(user.type) && rows.length > 0,
                      },
                      {
                        ...ops.exportList,
                        isShow: ({ search }) =>
                          accountType.supervise.eq(user.type) &&
                          isFuTian.value &&
                          search.startTime &&
                          search.endTime,
                      },
                    ],
                  },
                  isShow: () => {
                    return aiMessageType["11"] !== undefined;
                  },
                },
                component: () => import("@/views/app/znyj/aiyj/DataView"),
              },
              {
                path: "/app/znyj/aiyj/sblx",
                name: "aiyj_12",
                meta: {
                  title: "设备离线",
                  icon: defineAsyncComponent(() =>
                    import("~icons/majesticons/status-offline-line")
                  ),
                  body: {
                    hasVideo: () => aiMessageType["12"].hasVideo,
                    ops: [
                      {
                        ...ops.deleteBatch,
                        isShow: ({ rows }) =>
                          accountType.setup.eq(user.type) && rows.length > 0,
                      },
                      {
                        ...ops.exportList,
                        isShow: ({ search }) =>
                          accountType.supervise.eq(user.type) &&
                          isFuTian.value &&
                          search.startTime &&
                          search.endTime,
                      },
                    ],
                  },
                  isShow: () => {
                    return aiMessageType["12"] !== undefined;
                  },
                },
                component: () => import("@/views/app/znyj/aiyj/DataView"),
              },
            ],
          },
          {
            path: "/app/znyj/iot",
            meta: {
              title: "物联感知预警",
              icon: IconIOT,
              isShow: () => {
                return Object.keys(iotDeviceType).length > 0;
              },
            },
            component: RouterView,
            children: [
              // {
              //   path: "/app/znyj/iot/sbgj",
              //   name: "iot_41",
              //   meta: {
              //     title: "设备告警",
              //     icon: defineAsyncComponent(() =>
              //       import("~icons/ri/alarm-warning-line")
              //     ),
              //     isShow: () =>
              //       accountType.setup.eq(user.type) ||
              //       accountType.supervise.eq(user.type),
              //     body: {
              //       ops: [ops.export],
              //     },
              //   },
              //   component: () => import("@/views/app/znyj/iot/sbgj/IndexView"),
              // },
              // {
              //   path: "/app/znyj/iot/sblb",
              //   name: "iot_42",
              //   meta: {
              //     title: "设备管理",
              //     icon: defineAsyncComponent(() =>
              //       import("~icons/bxs/devices")
              //     ),
              //     isShow: () =>
              //       accountType.setup.eq(user.type) ||
              //       accountType.supervise.eq(user.type),
              //     body: {
              //       ops: [ops.export],
              //     },
              //   },
              //   component: () => import("@/views/app/znyj/iot/sblb/IndexView"),
              // },
              {
                path: "/app/znyj/iot/dsb",
                name: "iot_41",
                meta: {
                  title: "挡鼠板",
                  icon: IconMouse,
                  isShow: () => {
                    return iotDeviceType["41"] !== undefined;
                  },
                },
                component: () => import("@/views/app/znyj/iot/dsb/IndexView"),
              },
              {
                path: "/app/znyj/iot/bxwsd",
                name: "iot_42",
                meta: {
                  title: "冰箱温湿度",
                  icon: defineAsyncComponent(() =>
                    import("~icons/gg/smart-home-refrigerator")
                  ),
                  isShow: () => {
                    return iotDeviceType["42"] !== undefined;
                  },
                },
                component: () => import("@/views/app/znyj/iot/bxwsd/IndexView"),
              },
              {
                path: "/app/znyj/iot/xdg",
                name: "iot_43",
                meta: {
                  title: "消毒柜",
                  icon: defineAsyncComponent(() => import("~icons/bx/cabinet")),
                  isShow: () => {
                    return iotDeviceType["43"] !== undefined;
                  },
                },
                component: () => import("@/views/app/znyj/iot/xdg/IndexView"),
              },
              {
                path: "/app/znyj/iot/zjzt",
                name: "iot_44",
                meta: {
                  title: "专间状态",
                  icon: defineAsyncComponent(() =>
                    import("~icons/tabler/building-warehouse")
                  ),
                  isShow: () => {
                    return iotDeviceType["44"] !== undefined;
                  },
                },
                component: () => import("@/views/app/znyj/iot/zjzt/IndexView"),
              },
              {
                path: "/app/znyj/iot/zwxxdd",
                name: "iot_45",
                meta: {
                  title: "紫外线消毒灯",
                  icon: defineAsyncComponent(() =>
                    import("~icons/mdi/ceiling-fan-light")
                  ),
                  isShow: () => {
                    return iotDeviceType["45"] !== undefined;
                  },
                },
                component: () =>
                  import("@/views/app/znyj/iot/zwxxdd/IndexView"),
              },
              {
                path: "/app/znyj/iot/krqtjc",
                name: "iot_46",
                meta: {
                  title: "可燃气体检测",
                  icon: IconGAS,
                  isShow: () => {
                    return iotDeviceType["46"] !== undefined;
                  },
                },
                component: () =>
                  import("@/views/app/znyj/iot/krqtjc/IndexView"),
              },
              {
                path: "/app/znyj/iot/ywjc",
                name: "iot_47",
                meta: {
                  title: "烟雾检测",
                  icon: IconSmoke,
                },
                component: () => import("@/views/app/znyj/iot/ywjc/IndexView"),
              },
              {
                path: "/app/znyj/iot/znsb",
                name: "iot_48",
                meta: {
                  title: "智能水表",
                  icon: defineAsyncComponent(() =>
                    import("~icons/ri/water-flash-fill")
                  ),
                },
                component: () => import("@/views/app/znyj/iot/znsb/IndexView"),
              },
              {
                path: "/app/znyj/iot/zndb",
                name: "iot_49",
                meta: {
                  title: "智能电表",
                  icon: defineAsyncComponent(() =>
                    import("~icons/pepicons/electricity")
                  ),
                },
                component: () => import("@/views/app/znyj/iot/zndb/IndexView"),
              },
            ],
          },
          {
            path: "/app/znyj/iotStatus",
            meta: {
              title: "物联设备状态",
              icon: IconIOT,
            },
            component: RouterView,
            children: [
              {
                path: "/app/znyj/iotStatus/mcsb",
                meta: {
                  title: "门磁设备",
                  icon: defineAsyncComponent(() =>
                    import("~icons/icon-park-outline/pull-door")
                  ),
                  body: {
                    ops: [],
                    editPage: defineAsyncComponent(() =>
                      import("@/views/app/znyj/iotStatus/mcsb/EditView")
                    ),
                  },
                },
                component: () =>
                  import("@/views/app/znyj/iotStatus/mcsb/IndexView"),
              },
              {
                path: "/app/znyj/iotStatus/wsdsb",
                meta: {
                  title: "温湿度设备",
                  icon: defineAsyncComponent(() =>
                    import("~icons/carbon/temperature-celsius")
                  ),
                  body: {
                    ops: [],
                    editPage: defineAsyncComponent(() =>
                      import("@/views/app/znyj/iotStatus/wsdsb/EditView")
                    ),
                  },
                },
                component: () =>
                  import("@/views/app/znyj/iotStatus/wsdsb/IndexView"),
              },
              {
                path: "/app/znyj/iotStatus/zndb",
                meta: {
                  title: "智能电表",
                  icon: defineAsyncComponent(() =>
                    import("~icons/pepicons/electricity")
                  ),

                  body: {
                    ops: [],
                    editPage: defineAsyncComponent(() =>
                      import("@/views/app/znyj/iotStatus/zndb/EditView")
                    ),
                  },
                },
                component: () =>
                  import("@/views/app/znyj/iotStatus/zndb/IndexView"),
              },
              {
                path: "/app/znyj/iotStatus/znsb",
                meta: {
                  title: "智能水表",
                  icon: defineAsyncComponent(() =>
                    import("~icons/ri/water-flash-fill")
                  ),

                  body: {
                    ops: [],
                    editPage: defineAsyncComponent(() =>
                      import("@/views/app/znyj/iotStatus/znsb/EditView")
                    ),
                  },
                },
                component: () =>
                  import("@/views/app/znyj/iotStatus/znsb/IndexView"),
              },
              {
                path: "/app/znyj/iotStatus/newZndb",
                meta: {
                  title: "智能电表",
                  icon: defineAsyncComponent(() =>
                    import("~icons/pepicons/electricity")
                  ),
                  isShow: () => false,
                  body: {
                    ops: [
                      {
                        ...ops.create,
                        isShow: ({ org }) => org.isCompany,
                      },
                      ops.modify,
                      ops.detail,
                      ops.delete,
                      {
                        ...ops.export,
                        isShow: ({ org }) => org.isArea,
                      },
                    ],
                    editPage: defineAsyncComponent(() =>
                      import("@/views/app/znyj/iotStatus/newZndb/EditView")
                    ),
                  },
                },
                component: () =>
                  import("@/views/app/znyj/iotStatus/newZndb/IndexView"),
              },
              {
                path: "/app/znyj/iotStatus/krqt",
                meta: {
                  title: "可燃气体检测设备",
                  icon: IconGAS,
                  body: {
                    ops: [],
                    editPage: defineAsyncComponent(() =>
                      import("@/views/app/znyj/iotStatus/krqt/EditView")
                    ),
                  },
                },
                component: () =>
                  import("@/views/app/znyj/iotStatus/krqt/IndexView"),
              },
              {
                path: "/app/znyj/iotStatus/ywcgq",
                meta: {
                  title: "烟雾传感器",
                  icon: IconSmoke,
                  body: {
                    ops: [],
                    editPage: defineAsyncComponent(() =>
                      import("@/views/app/znyj/iotStatus/ywcgq/EditView")
                    ),
                  },
                },
                component: () =>
                  import("@/views/app/znyj/iotStatus/ywcgq/IndexView"),
              },
              {
                path: "/app/znyj/iotStatus/locator",
                meta: {
                  title: "车载定位仪",
                  icon: IconLocation,
                  body: {
                    ops: [],
                    editPage: defineAsyncComponent(() =>
                      import("@/views/app/znyj/iotStatus/locator/EditView")
                    ),
                  },
                },
                component: () =>
                  import("@/views/app/znyj/iotStatus/locator/IndexView"),
              },
              {
                path: "/app/znyj/iotStatus/kqkg",
                meta: {
                  title: "空气开关",
                  icon: defineAsyncComponent(() =>
                    import("~icons/bi/nintendo-switch")
                  ),
                  body: {
                    ops: [],
                    editPage: defineAsyncComponent(() =>
                      import("@/views/app/znyj/iotStatus/kqkg/EditView")
                    ),
                  },
                },
                component: () =>
                  import("@/views/app/znyj/iotStatus/kqkg/IndexView"),
              },
			{
				path: "/app/znyj/iotStatus/sqcgq",
				meta: {
					title: "水浸传感器",
					icon: defineAsyncComponent(() =>
						import("~icons/streamline/legal-justice-scale-2-office-work-legal-scale-justice-unequal-company-arbitration-unbalance-court")
					),
					body: {
						ops: [],
						editPage: defineAsyncComponent(() =>
							import("@/views/app/znyj/iotStatus/sqcgq/EditView")
						),
					},
				},
				component: () =>
				import("@/views/app/znyj/iotStatus/sqcgq/IndexView"),
			},
            ],
          },
          {
            path: "/app/znyj/sbzt",
            meta: {
              title: "设备状态",
              icon: defineAsyncComponent(() => import("~icons/bi/wifi-off")),
            },
            component: RouterView,
            children: [
              {
                path: "/app/znyj/sbzt/aibox",
                meta: {
                  title: "超脑",
                  icon: IconPostageFill,
                },
                component: () =>
                  import("@/views/app/system/sbgl/aibox/IndexView"),
              },
              {
                path: "/app/znyj/sbzt/camera",
                meta: {
                  title: "相机",
                  icon: IconCamera,
                },
                component: () =>
                  import("@/views/app/system/sbgl/camera/IndexView"),
              },
              {
                path: "/app/znyj/sbzt/nvr",
                meta: {
                  title: "NVR",
                  icon: defineAsyncComponent(() =>
                    import("~icons/clarity/hard-disk-outline-badged")
                  ),
                },
                component: () =>
                  import("@/views/app/system/sbgl/nvr/IndexView"),
              },
              {
                path: "/app/znyj/sbzt/zhgsp",
                meta: {
                  title: "智慧公示屏",
                  icon: defineAsyncComponent(() =>
                    import("~icons/codicon/screen-full")
                  ),
                },
                component: () =>
                  import("@/views/app/system/sbgl/zhgsp/IndexView"),
              },
              {
                path: "/app/znyj/sbzt/cjj",
                meta: {
                  title: "晨检机",
                  icon: defineAsyncComponent(() =>
                    import("~icons/icon-park-outline/people-safe")
                  ),
                },
                component: () =>
                  import("@/views/app/system/sbgl/cjj/IndexView"),
              },
              {
                path: "/app/znyj/sbzt/foodCar",
                meta: {
                  title: "配餐车",
                  icon: defineAsyncComponent(() =>
                    import("~icons/mdi/truck-cargo-container")
                  ),
                },
                component: () => import("@/views/app/znyj/foodCar/IndexView"),
              },
            ],
          },
        ],
      },
      {
        path: "/app/jdgl",
        meta: {
          title: "监督管理",
        },
        component: RouterView,
        children: [
          {
            path: "/app/jdgl/dtdj",
            meta: {
              title: "动态等级",
              icon: defineAsyncComponent(() =>
                import("~icons/carbon/skill-level-advanced")
              ),
              isShow: () =>
                accountType.setup.eq(user.type) ||
                accountType.supervise.eq(user.type),
              body: {
                ops: [ops.download, ops.export, ops.exportList],
              },
            },
            component: () => import("@/views/app/jdgl/dtdj/IndexView"),
          },
          {
            path: "/app/jdgl/xsjc",
            meta: {
              title: "线上检查",
              icon: defineAsyncComponent(() =>
                import("~icons/mdi/text-box-check-outline")
              ),
              isShow: () =>
                accountType.setup.eq(user.type) ||
                accountType.supervise.eq(user.type),
              body: {
                ops: [
                  {
                    ...ops.create,
                    isShow: ({ org }) =>
                      org.isCompany &&
                      (accountType.setup.eq(user.type) ||
                        accountType.supervise.eq(user.type)),
                  },
                  {
                    ...ops.delete,
                    isShow: () =>
                      accountType.setup.eq(user.type) ||
                      accountType.supervise.eq(user.type),
                  },
                ],
                editPage: defineAsyncComponent(() =>
                  import("@/views/app/jdgl/xsjc/EditView")
                ),
              },
            },
            component: () => import("@/views/app/jdgl/xsjc/IndexView"),
          },
          {
            path: "/app/jdgl/xxjc",
            meta: {
              title: "日常监督",
              icon: defineAsyncComponent(() =>
                import("~icons/mdi/text-box-check")
              ),
              isShow: () =>
                accountType.setup.eq(user.type) ||
                accountType.supervise.eq(user.type),
              body: {
                ops: [ops.download, ops.export, ops.exportList],
              },
            },
            component: () => import("@/views/app/jdgl/xxjc/IndexView"),
          },
          {
            path: "/app/jdgl/xsxc",
            meta: {
              title: "线上巡查",
              icon: defineAsyncComponent(() =>
                import("~icons/mdi/text-box-check")
              ),
              isShow: () =>
                accountType.setup.eq(user.type) ||
                (accountType.supervise.eq(user.type) && isFuTian.value),
            },
            component: () => import("@/views/app/jdgl/xsxc/IndexView"),
          },
          {
            path: "/app/jdgl/tsjy",
            meta: {
              title: "投诉建议",
              icon: defineAsyncComponent(() =>
                import("~icons/ri/file-info-fill")
              ),
            },
            component: () => import("@/views/app/jdgl/tsjy/IndexView"),
          },
          {
            path: "/app/jdgl/sazsph",
            meta: {
              title: "食安指数排行",
              icon: defineAsyncComponent(() =>
                import("~icons/cil/vertical-align-bottom1")
              ),
              isShow: () =>
                accountType.setup.eq(user.type) ||
                accountType.supervise.eq(user.type),
              sidebar: {
                org: {
                  level: levelType.street,
                },
              },
            },
            component: () => import("@/views/app/jdgl/sazsph/IndexView"),
          },
          /*{
                    path: '/app/jdgl/sjtj',
                    meta: {
                        title: '数据统计',
                        icon: IconFileEarmarkBarGraph
                    },
                    component: () => import('@/views/app/jdgl/sjtj/IndexView'),
                },*/
          {
            path: "/app/jdgl/xypt",
            meta: {
              title: "信用平台",
              icon: defineAsyncComponent(() =>
                import("~icons/fa6-regular/credit-card")
              ),
              // isShow: () => accountType.supervise.eq(user.type),
              isShow: () => false,
              sidebar: {
                org: {
                  level: levelType.area,
                },
              },
            },
            component: RouterView,
            children: [
              {
                path: "/app/jdgl/xypt/xybg",
                meta: {
                  // title: "企业信用报告查询",
                  title: getName(3),
                  icon: defineAsyncComponent(() =>
                    import("~icons/carbon/report")
                  ),
                  body: {
                    ops: [
                      {
                        ...ops.create,
                        isShow: ({ org }) => org.isOrg,
                      },
                      ops.modify,
                      ops.detail,
                      ops.delete,
                    ],
                  },
                },
                component: () => import("@/views/app/jdgl/xypt/xybg/IndexView"),
              },
              {
                path: "/app/jdgl/xypt/xyf",
                meta: {
                  // title: "企业信用分查询",
                  title: getName(4),
                  icon: defineAsyncComponent(() =>
                    import("~icons/material-symbols/credit-score-outline-sharp")
                  ),
                  body: {
                    ops: [
                      {
                        ...ops.create,
                        isShow: ({ org }) => org.isOrg,
                      },
                      ops.modify,
                      ops.detail,
                      ops.delete,
                    ],
                  },
                },
                component: () => import("@/views/app/jdgl/xypt/xyf/IndexView"),
              },
              {
                path: "/app/jdgl/xypt/hmdxx",
                meta: {
                  title: "信用黑名单信息查询",
                  icon: defineAsyncComponent(() =>
                    import("~icons/codicon/account")
                  ),
                  body: {
                    ops: [
                      {
                        ...ops.create,
                        isShow: ({ org }) => org.isOrg,
                      },
                      ops.modify,
                      ops.detail,
                      ops.delete,
                    ],
                  },
                },
                component: () =>
                  import("@/views/app/jdgl/xypt/hmdxx/IndexView"),
              },
            ],
          },
        ],
      },
      {
        path: "/app/jxc",
        meta: {
          title: "进销存",
          navbar: {
            isShow: () => false,
          },
          sidebar: {
            isShow: () => false,
          },
          isShow: () =>
            accountType.supervise.eq(user.type) ||
            accountType.company.eq(user.type),
        },
        component: () => import("@/views/app/jxc/IndexView"),
      },
      {
        path: "/app/gyl",
        meta: {
          title: "供应链",
          navbar: {
            isShow: () => false,
          },
          sidebar: {
            isShow: () => false,
          },
          isShow: () => accountType.company.eq(user.type) && isWh,
        },
        component: () => import("@/views/app/gyl/IndexView"),
      },

      {
        path: "/app/sazx",
        meta: {
          title: "食安资讯",
          isShow: () =>
            accountType.setup.eq(user.type) ||
            accountType.supervise.eq(user.type),
          sidebar: {
            org: {
              level: levelType.area,
            },
          },
        },
        component: RouterView,
        children: [
          {
            path: "/app/sazx/zxgl",
            meta: {
              title: "资讯管理",
              icon: defineAsyncComponent(() =>
                import("~icons/ic/baseline-newspaper")
              ),
              body: {
                ops: [
                  {
                    ...ops.create,
                    isShow: ({ org }) => org.isOrg,
                  },
                  ops.modify,
                  ops.detail,
                  ops.delete,
                ],
                editPage: defineAsyncComponent(() =>
                  import("@/views/app/sazx/zxgl/EditView")
                ),
              },
            },
            component: () => import("@/views/app/sazx/zxgl/IndexView"),
          },
          {
            path: "/app/sazx/sapx",
            meta: {
              title: "食安培训",
              icon: defineAsyncComponent(() =>
                import("~icons/ic/baseline-model-training")
              ),
              body: {
                ops: [
                  {
                    ...ops.create,
                    isShow: ({ org }) => org.isOrg,
                  },
                  ops.modify,
                  ops.detail,
                  ops.delete,
                ],
                editPage: defineAsyncComponent(() =>
                  import("@/views/app/sazx/sapx/EditView")
                ),
              },
            },
            component: () => import("@/views/app/sazx/sapx/IndexView"),
          },
          {
            path: "/app/sazx/yqlj",
            meta: {
              title: "友情链接",
              icon: defineAsyncComponent(() => import("~icons/ri/links-fill")),
              body: {
                ops: [
                  {
                    ...ops.create,
                    isShow: ({ org }) => org.isOrg,
                  },
                  ops.modify,
                  ops.detail,
                  ops.delete,
                ],
                editPage: defineAsyncComponent(() =>
                  import("@/views/app/sazx/yqlj/EditView")
                ),
              },
            },
            component: () => import("@/views/app/sazx/yqlj/IndexView"),
          },
        ],
      },
      {
        path: "/app/dsjbg",
        meta: {
          title: "大数据报告",
          sidebar: {
            org: {
              level: levelType.street,
            },
            module: {
              isShow: () => false,
            },
          },
          isShow: () =>
            accountType.setup.eq(user.type) ||
            accountType.supervise.eq(user.type),
          body: {
            ops: [ops.detail],
            editPage: defineAsyncComponent(() =>
              // isFuTian.value ? import("@/views/app/dsjbg/FuTian") :
              import("@/views/app/dsjbg/EditView")
            ),
          },
        },
        component: () => import("@/views/app/dsjbg/IndexView"),
      },
      {
        path: "/app/yyfx",
        meta: {
          title: "营养分析",
          navbar: {
            isShow: () => false,
          },
          sidebar: {
            isShow: () => false,
          },
          isShow: () =>
            accountType.company.eq(user.type) && nutritionAnalysisEnable === 1,
        },
        component: () => import("@/views/app/yyfx/IndexView"),
      },

      {
        path: "/app/system",
        meta: {
          title: "系统设置",
          isShow: () =>
            accountType.setup.eq(user.type) ||
            (accountType.supervise.eq(user.type) && isFuTian.value),
          sidebar: {
            reverse: () => true,
          },
        },
        component: RouterView,
        children: [
          {
            path: "/app/system/qxgl",
            meta: {
              title: "权限管理",
              icon: defineAsyncComponent(() =>
                import("~icons/ant-design/safety-certificate-filled")
              ),
            },
            component: RouterView,
            children: [
              {
                path: "/app/system/qxgl/dwgl",
                meta: {
                  title: "单位管理",
                  icon: defineAsyncComponent(() =>
                    import("~icons/bx/bxs-school")
                  ),
                  sidebar: {
                    org: {
                      level: levelType.street,
                    },
                  },
                  body: {
                    ops: [
                      {
                        ...ops.create,
                        isShow: ({ org }) => org.isStreet,
                      },
                      ops.modify,
                      ops.detail,
                      ops.delete,
                      {
                        ...ops.export,
                        isShow: ({ org }) => org.isArea,
                      },
                    ],
                    editPage: defineAsyncComponent(() =>
                      import("@/views/app/system/qxgl/dwgl/EditView")
                    ),
                  },
                },
                component: () =>
                  import("@/views/app/system/qxgl/dwgl/IndexView"),
              },
              {
                path: "/app/system/qxgl/dkgl",
                meta: {
                  title: "档口管理",
                  isShow: () =>
                    accountType.setup.eq(user.type) ||
                    accountType.supervise.eq(user.type),
                  icon: defineAsyncComponent(() =>
                    import("~icons/bi/shop-window")
                  ),
                  sidebar: {
                    org: {
                      level: levelType.company,
                    },
                  },
                  body: {
                    ops: [
                      {
                        ...ops.create,
                        isShow: ({ org }) => org.isCompany,
                      },
                      ops.modify,
                      ops.detail,
                      ops.delete,
                      {
                        ...ops.export,
                        isShow: ({ org }) => org.isArea,
                      },
                    ],
                    editPage: defineAsyncComponent(() =>
                      import("@/views/app/system/qxgl/dkgl/EditView")
                    ),
                  },
                },
                component: () =>
                  import("@/views/app/system/qxgl/dkgl/IndexView"),
              },
              {
                path: "/app/system/qxgl/sqgl",
                meta: {
                  title: "商圈管理",
                  icon: defineAsyncComponent(() =>
                    import("~icons/ion/business-sharp")
                  ),
                  sidebar: {
                    org: {
                      level: levelType.street,
                    },
                  },
                  body: {
                    ops: [
                      {
                        ...ops.create,
                        isShow: ({ org }) => org.isStreet,
                      },
                      ops.modify,
                      ops.detail,
                      ops.delete,
                      {
                        ...ops.export,
                        isShow: ({ org }) => org.isArea,
                      },
                    ],
                    editPage: defineAsyncComponent(() =>
                      import("@/views/app/system/qxgl/sqgl/EditView")
                    ),
                  },
                },
                component: () =>
                  import("@/views/app/system/qxgl/sqgl/IndexView"),
              },
              {
                path: "/app/system/qxgl/yhgl",
                meta: {
                  title: "用户管理",
                  icon: defineAsyncComponent(() =>
                    import("~icons/ph/users-four")
                  ),
                  body: {
                    ops: [
                      {
                        ...ops.create,
                        isShow: ({ org }) => org.id,
                      },
                      ops.modify,
                      ops.detail,
                      ops.delete,
                      {
                        ...ops.export,
                        isShow: ({ org }) => org.isArea || org.isCompany,
                      },
                    ],
                    editPage: defineAsyncComponent(() =>
                      import("@/views/app/system/qxgl/yhgl/EditView")
                    ),
                  },
                },
                component: () =>
                  import("@/views/app/system/qxgl/yhgl/IndexView"),
              },
              {
                path: "/app/system/qxgl/gzrygl",
                meta: {
                  title: "工作人员管理",
                  icon: defineAsyncComponent(() =>
                    import("~icons/healthicons/health-worker-form")
                  ),
                  body: {
                    ops: [
                      {
                        ...ops.create,
                        isShow: ({ org }) => org.isCompany || org.isDangkou,
                      },
                      ops.modify,
                      ops.detail,
                      ops.delete,
                      {
                        ...ops.export,
                        isShow: ({ org }) => org.isArea,
                      },
                    ],
                    editPage: defineAsyncComponent(() =>
                      import("@/views/app/system/qxgl/gzrygl/EditView")
                    ),
                  },
                },
                component: () =>
                  import("@/views/app/system/qxgl/gzrygl/IndexView"),
              },
              {
                path: "/app/system/qxgl/zzgl",
                meta: {
                  title: "组织管理",
                  icon: defineAsyncComponent(() =>
                    import("~icons/clarity/organization-solid")
                  ),
                  sidebar: {
                    org: {
                      isShow: () => false,
                    },
                  },
                },
                component: () =>
                  import("@/views/app/system/qxgl/zzgl/IndexView"),
              },
              {
                path: "/app/system/qxgl/qyzc",
                meta: {
                  // title: "企业注册管理",
                  title:getName(5),
                  icon: defineAsyncComponent(() =>
                    import("~icons/bx/bxs-school")
                  ),
                  sidebar: {
                    org: {
                      isShow: () => false,
                    },
                  },
                  body: {
                    ops: [
                      {
                        ...ops.modify,
                        isShow: (rows) => rows.row.status === 0,
                      },

                      ops.detail,
                      ops.delete,
                      {
                        ...ops.export,
                        isShow: ({ org }) => org.isArea,
                      },
                    ],
                    editPage: defineAsyncComponent(() =>
                      import("@/views/app/system/qxgl/qyzc/EditView")
                    ),
                  },
                },

                component: () =>
                  import("@/views/app/system/qxgl/qyzc/IndexView"),
              },
            ],
          },
          {
            path: "/app/system/sbgl",
            meta: {
              title: "设备管理",
              icon: defineAsyncComponent(() => import("~icons/bi/cpu-fill")),
            },
            component: RouterView,
            children: [
              {
                path: "/app/system/sbgl/aibox",
                meta: {
                  title: "超脑",
                  icon: IconPostageFill,
                  body: {
                    ops: [
                      {
                        ...ops.create,
                        isShow: ({ org }) => org.isCompany,
                      },
                      ops.modify,
                      ops.detail,
                      ops.delete,
                      {
                        ...ops.export,
                        isShow: ({ org }) => org.isArea,
                      },
                    ],
                    editPage: defineAsyncComponent(() =>
                      import("@/views/app/system/sbgl/aibox/EditView")
                    ),
                  },
                },
                component: () =>
                  import("@/views/app/system/sbgl/aibox/IndexView"),
              },
              {
                path: "/app/system/sbgl/camera",
                meta: {
                  title: "相机",
                  icon: IconCamera,
                  body: {
                    ops: [
                      {
                        ...ops.create,
                        isShow: ({ org }) => org.isCompany,
                      },
                      {
                        ...ops.deleteBatch,
                      },
                      ops.modify,
                      ops.detail,
                      ops.delete,
                      {
                        ...ops.export,
                        isShow: ({ org }) => org.isArea,
                      },
                    ],
                    editPage: defineAsyncComponent(() =>
                      import("@/views/app/system/sbgl/camera/EditView")
                    ),
                  },
                },
                component: () =>
                  import("@/views/app/system/sbgl/camera/IndexView"),
              },
              {
                path: "/app/system/sbgl/nvr",
                meta: {
                  title: "NVR",
                  icon: defineAsyncComponent(() =>
                    import("~icons/clarity/hard-disk-outline-badged")
                  ),
                  body: {
                    ops: [
                      {
                        ...ops.create,
                        isShow: ({ org }) => org.isCompany,
                      },
                      ops.modify,
                      ops.detail,
                      ops.delete,
                      {
                        ...ops.export,
                        isShow: ({ org }) => org.isCity || org.isArea,
                      },
                    ],
                    editPage: defineAsyncComponent(() =>
                      import("@/views/app/system/sbgl/nvr/EditView")
                    ),
                  },
                },
                component: () =>
                  import("@/views/app/system/sbgl/nvr/IndexView"),
              },
              {
                path: "/app/system/sbgl/zhgsp",
                meta: {
                  title: "智慧公示屏",
                  icon: defineAsyncComponent(() =>
                    import("~icons/codicon/screen-full")
                  ),
                  body: {
                    ops: [
                      {
                        ...ops.create,
                        isShow: ({ org }) => org.isCompany,
                      },
                      {
                        ...ops.upgradation,
                        isShow: () => isFuTian.value,
                      },
                      ops.modify,
                      ops.detail,
                      ops.delete,
                      {
                        ...ops.export,
                        isShow: ({ org }) => org.isArea,
                      },
                    ],
                    editPage: defineAsyncComponent(() =>
                      import("@/views/app/system/sbgl/zhgsp/EditView")
                    ),
                  },
                },
                component: () =>
                  import("@/views/app/system/sbgl/zhgsp/IndexView"),
              },
              {
                path: "/app/system/sbgl/cjj",
                meta: {
                  title: "晨检机",
                  icon: defineAsyncComponent(() =>
                    import("~icons/icon-park-outline/people-safe")
                  ),
                  body: {
                    ops: [
                      {
                        ...ops.create,
                        isShow: ({ org }) => org.isCompany,
                      },
                      ops.modify,
                      ops.detail,
                      ops.delete,
                      {
                        ...ops.export,
                        isShow: ({ org }) => org.isArea,
                      },
                    ],
                    editPage: defineAsyncComponent(() =>
                      import("@/views/app/system/sbgl/cjj/EditView")
                    ),
                  },
                },
                component: () =>
                  import("@/views/app/system/sbgl/cjj/IndexView"),
              },
              {
                path: "/app/system/sbgl/foodCar",
                meta: {
                  title: "配餐车管理",
                  icon: defineAsyncComponent(() =>
                    import("~icons/icon-park-outline/people-safe")
                  ),
                  body: {
                    ops: [
                      {
                        ...ops.create,
                        isShow: ({ org }) => org.isCompany,
                      },
                      ops.modify,
                      ops.detail,
                      ops.delete,
                      {
                        ...ops.export,
                        isShow: ({ org }) => org.isArea,
                      },
                    ],
                    editPage: defineAsyncComponent(() =>
                      import("@/views/app/system/sbgl/foodCar/EditView")
                    ),
                  },
                },
                component: () =>
                  import("@/views/app/system/sbgl/foodCar/IndexView"),
              },
            ],
          },
          {
            path: "/app/system/iot",
            meta: {
              title: "物联设备管理",
              icon: IconIOT,
            },
            component: RouterView,
            children: [
              {
                path: "/app/system/iot/mcsb",
                meta: {
                  title: "门磁设备",
                  icon: defineAsyncComponent(() =>
                    import("~icons/icon-park-outline/pull-door")
                  ),
                  body: {
                    ops: [
                      {
                        ...ops.create,
                        isShow: ({ org }) => org.isCompany,
                      },
                      ops.modify,
                      ops.detail,
                      ops.delete,
                      {
                        ...ops.export,
                        isShow: ({ org }) => org.isArea,
                      },
                    ],
                    editPage: defineAsyncComponent(() =>
                      import("@/views/app/system/iot/mcsb/EditView")
                    ),
                  },
                },
                component: () =>
                  import("@/views/app/system/iot/mcsb/IndexView"),
              },
              {
                path: "/app/system/iot/wsdsb",
                meta: {
                  title: "温湿度设备",
                  icon: defineAsyncComponent(() =>
                    import("~icons/carbon/temperature-celsius")
                  ),
                  body: {
                    ops: [
                      {
                        ...ops.create,
                        isShow: ({ org }) => org.isCompany,
                      },
                      ops.modify,
                      ops.detail,
                      ops.delete,
                      {
                        ...ops.export,
                        isShow: ({ org }) => org.isArea,
                      },
                    ],
                    editPage: defineAsyncComponent(() =>
                      import("@/views/app/system/iot/wsdsb/EditView")
                    ),
                  },
                },
                component: () =>
                  import("@/views/app/system/iot/wsdsb/IndexView"),
              },
              {
                path: "/app/system/iot/zndb",
                meta: {
                  title: "智能电表",
                  icon: defineAsyncComponent(() =>
                    import("~icons/pepicons/electricity")
                  ),

                  body: {
                    ops: [
                      {
                        ...ops.create,
                        isShow: ({ org }) => org.isCompany,
                      },
                      ops.modify,
                      ops.detail,
                      ops.delete,
                      {
                        ...ops.export,
                        isShow: ({ org }) => org.isArea,
                      },
                    ],
                    editPage: defineAsyncComponent(() =>
                      import("@/views/app/system/iot/zndb/EditView")
                    ),
                  },
                },
                component: () =>
                  import("@/views/app/system/iot/zndb/IndexView"),
              },
              {
                path: "/app/system/iot/znsb",
                meta: {
                  title: "智能水表",
                  icon: defineAsyncComponent(() =>
                    import("~icons/ri/water-flash-fill")
                  ),

                  body: {
                    ops: [
                      {
                        ...ops.create,
                        isShow: ({ org }) => org.isCompany,
                      },
                      ops.modify,
                      ops.detail,
                      ops.delete,
                      {
                        ...ops.export,
                        isShow: ({ org }) => org.isArea,
                      },
                    ],
                    editPage: defineAsyncComponent(() =>
                      import("@/views/app/system/iot/znsb/EditView")
                    ),
                  },
                },
                component: () =>
                  import("@/views/app/system/iot/znsb/IndexView"),
              },
              {
                path: "/app/system/iot/newZndb",
                meta: {
                  title: "智能电表",
                  icon: defineAsyncComponent(() =>
                    import("~icons/pepicons/electricity")
                  ),
                  isShow: () => false,
                  body: {
                    ops: [
                      {
                        ...ops.create,
                        isShow: ({ org }) => org.isCompany,
                      },
                      ops.modify,
                      ops.detail,
                      ops.delete,
                      {
                        ...ops.export,
                        isShow: ({ org }) => org.isArea,
                      },
                    ],
                    editPage: defineAsyncComponent(() =>
                      import("@/views/app/system/iot/newZndb/EditView")
                    ),
                  },
                },
                component: () =>
                  import("@/views/app/system/iot/newZndb/IndexView"),
              },
              {
                path: "/app/system/iot/krqt",
                meta: {
                  title: "可燃气体检测设备",
                  icon: IconGAS,
                  body: {
                    ops: [
                      {
                        ...ops.create,
                        isShow: ({ org }) => org.isCompany,
                      },
                      ops.modify,
                      ops.detail,
                      ops.delete,
                      {
                        ...ops.export,
                        isShow: ({ org }) => org.isArea,
                      },
                    ],
                    editPage: defineAsyncComponent(() =>
                      import("@/views/app/system/iot/krqt/EditView")
                    ),
                  },
                },
                component: () =>
                  import("@/views/app/system/iot/krqt/IndexView"),
              },
              {
                path: "/app/system/iot/ywcgq",
                meta: {
                  title: "烟雾传感器",
                  icon: IconSmoke,
                  body: {
                    ops: [
                      {
                        ...ops.create,
                        isShow: ({ org }) => org.isCompany,
                      },
                      ops.modify,
                      ops.detail,
                      ops.delete,
                    ],
                    editPage: defineAsyncComponent(() =>
                      import("@/views/app/system/iot/ywcgq/EditView")
                    ),
                  },
                },
                component: () =>
                  import("@/views/app/system/iot/ywcgq/IndexView"),
              },
              {
                path: "/app/system/iot/locator",
                meta: {
                  title: "车载定位仪",
                  icon: IconLocation,
                  body: {
                    ops: [
                      {
                        ...ops.create,
                        isShow: ({ org }) => org.isCompany,
                      },
                      ops.modify,
                      ops.detail,
                      ops.delete,
                    ],
                    editPage: defineAsyncComponent(() =>
                      import("@/views/app/system/iot/locator/EditView")
                    ),
                  },
                },
                component: () =>
                  import("@/views/app/system/iot/locator/IndexView"),
              },
              {
                path: "/app/system/iot/kqkg",
                meta: {
                  title: "空气开关",
                  icon: defineAsyncComponent(() =>
                    import("~icons/bi/nintendo-switch")
                  ),
                  body: {
                    ops: [
                      {
                        ...ops.create,
                        isShow: ({ org }) => org.isCompany,
                      },
                      ops.modify,
                      ops.detail,
                      ops.delete,
                    ],
                    editPage: defineAsyncComponent(() =>
                      import("@/views/app/system/iot/kqkg/EditView")
                    ),
                  },
                },
                component: () =>
                  import("@/views/app/system/iot/kqkg/IndexView"),
              },
			{
			path: "/app/system/iot/sqcgq",
			meta: {
				title: "水浸传感器",
				icon: defineAsyncComponent(() =>
				import("~icons/streamline/legal-justice-scale-2-office-work-legal-scale-justice-unequal-company-arbitration-unbalance-court")
				),
				body: {
					ops: [
						{
							...ops.create,
							isShow: ({ org }) => org.isCompany,
						},
					],
					editPage: defineAsyncComponent(() =>
						import("@/views/app/system/iot/sqcgq/EditView")
					),
				},
			},
			component: () =>
				import("@/views/app/system/iot/sqcgq/IndexView"),
			},
              // {
              //   path: "/app/system/iot/sycj",
              //   meta: {
              //     title: "场景管理",
              //     icon: defineAsyncComponent(() =>
              //       import("~icons/material-symbols/scene")
              //     ),
              //     sidebar: {
              //       org: {
              //         isShow: () => false,
              //       },
              //     },
              //     body: {
              //       ops: [
              //         {
              //           ...ops.create,
              //           // isShow: ({ org }) => org.isCompany,
              //         },
              //         {
              //           ...ops.modify,
              //           isShow: ({ row }) => row.id !== "DEFAULT",
              //         },
              //         {
              //           ...ops.detail,
              //           isShow: ({ row }) => row.id !== "DEFAULT",
              //         },
              //         {
              //           ...ops.delete,
              //           isShow: ({ row }) => row.id !== "DEFAULT",
              //         },
              //       ],
              //       editPage: defineAsyncComponent(() =>
              //         import("@/views/app/system/iot/sycj/EditView")
              //       ),
              //     },
              //   },
              //   component: () =>
              //     import("@/views/app/system/iot/sycj/IndexView"),
              // },
              // {
              //   path: "/app/system/iot/sblb",
              //   meta: {
              //     title: "设备管理",
              //     icon: defineAsyncComponent(() =>
              //       import("~icons/bxs/devices")
              //     ),
              //     body: {
              //       ops: [
              //         {
              //           ...ops.create,
              //           isShow: ({ org }) => org.isCompany,
              //         },
              //         ops.modify,
              //         ops.detail,
              //         ops.delete,
              //       ],
              //       editPage: defineAsyncComponent(() =>
              //         import("@/views/app/system/iot/sblb/EditView")
              //       ),
              //     },
              //   },
              //   component: () =>
              //     import("@/views/app/system/iot/sblb/IndexView"),
              // },
            ],
          },

          {
            path: "/app/system/config",
            meta: {
              title: "系统配置",
              icon: defineAsyncComponent(() =>
                import("~icons/carbon/cloud-satellite-config")
              ),
              sidebar: {
                org: {
                  isShow: () => false,
                },
              },
            },
            component: RouterView,
            children: [
              {
                path: "/app/system/baseInfo",
                meta: {
                  title: "基数配置",
                  icon: defineAsyncComponent(() =>
                    import("~icons/ic/baseline-10k")
                  ),
                },
                component: () => import("@/views/app/system/config/BaseInfo"),
              },
            ],
          },
        ],
      },
    ],
  },
];

export const load = (pages = _routes) => {
  return pages
    .filter((p) => {
      const isShow = p.meta.isShow;
      return !isShow || isShow();
    }) //过滤不显示的路由
    .map((p) => {
      p.children = load(p.children || []);
      return p;
    }); //递归子路由
};
